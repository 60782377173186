import { userSettingsActionTypes as T } from './action-types';

const getUserSettingsInfo = () => ({
  type: T.GET_USER_SETTINGS_INFO,
});

const getUserSettingsInfoSuccess = (data: UserSettingsModule.UserFormData) => ({
  type: T.GET_USER_SETTINGS_INFO_SUCCESS,
  payload: { data },
});

const getUserSettingsInfoError = (error: Error) => ({
  type: T.GET_USER_SETTINGS_INFO_ERROR,
  payload: error,
});

const patchUserSettingsInfo = (payload: UserSettingsModule.UserSettingsForm) => ({
  type: T.PATCH_USER_SETTINGS_INFO,
  payload,
});

const patchUserSettingsInfoSuccess = (data: UserSettingsModule.UserFormData) => ({
  type: T.PATCH_USER_SETTINGS_INFO_SUCCESS,
  payload: { data },
});

const patchUserSettingsInfoError = (error: Error) => ({
  type: T.PATCH_USER_SETTINGS_INFO_ERROR,
  payload: error,
});

export const userSettingsActions = {
  getUserSettingsInfo,
  getUserSettingsInfoSuccess,
  getUserSettingsInfoError,
  patchUserSettingsInfo,
  patchUserSettingsInfoSuccess,
  patchUserSettingsInfoError,
};
