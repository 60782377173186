import { Action } from 'common/interfaces/root';
import { userSettingsActionTypes as T } from 'modules/user-settings/store/actions';

const initState: UserSettingsModule.UserFormStore = {
  loading: true,
  error: null,
  data: null,
  loadingUpdate: false,
};

export default (
  state: UserSettingsModule.UserFormStore = initState,
  { type, payload }: Action
): UserSettingsModule.UserFormStore => {
  switch (type) {
    case T.GET_USER_SETTINGS_INFO:
      return { ...state, loading: true };

    case T.GET_USER_SETTINGS_INFO_SUCCESS:
      return { ...state, loading: false, data: payload.data };

    case T.GET_USER_SETTINGS_INFO_ERROR:
      return { ...state, loading: false, error: payload };

    case T.PATCH_USER_SETTINGS_INFO:
      return { ...state, loadingUpdate: true };

    case T.PATCH_USER_SETTINGS_INFO_SUCCESS:
      return { ...state, loadingUpdate: false, data: payload.data };

    case T.PATCH_USER_SETTINGS_INFO_ERROR:
      return { ...state, loadingUpdate: false, error: payload };

    default:
      return state;
  }
};
