import { Box, Button, Typography } from '@mui/material';
import { paper } from 'common/constants/colors';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { useMemo } from 'react';
import { StoreHelpers, TooltipRenderProps } from 'react-joyride';

const styles: Styles = {
  container: { width: 600, backgroundColor: paper, p: 3, borderRadius: '10px' },
  title: { fontSize: 18, fontWeight: 600 },
  text: { fontSize: 14, lineHeight: '26px', mt: 1.5 },
  footer: { mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  btns: { display: 'flex', gap: 0.5 },
  btn: { width: 120 },
  stepper: { display: 'flex', gap: 1 },
  step: { width: 8, minWidth: 8, height: 8, borderRadius: '50%', backgroundColor: '#B2BFC6', cursor: 'pointer' },
  activeStep: { backgroundColor: theme.palette.primary.main, cursor: 'default' },
};

export function CustomTooltip({
  continuous,
  step,
  primaryProps,
  tooltipProps,
  index,
  size,
  skipProps,
  isLastStep,
  helpers,
}: TooltipRenderProps & { helpers: StoreHelpers }) {
  const steps = useMemo(() => {
    const out = [];
    for (let i = 0; i < size; i += 1) {
      out.push({ active: index === i, index: i });
    }
    return out;
  }, [index, size]);

  return (
    <Box {...tooltipProps} sx={styles.container}>
      {step.title && <Typography sx={styles.title}>{step.title}</Typography>}
      <Typography sx={styles.text}>{step.content}</Typography>
      <Box sx={styles.footer}>
        <Box sx={styles.stepper}>
          {steps.map(step => (
            <Box
              key={step.index}
              component="span"
              sx={[styles.step, step.active && styles.activeStep]}
              onClick={() => !step.active && helpers?.go(step.index)}
            />
          ))}
        </Box>
        <Box sx={styles.btns}>
          {!isLastStep && (
            <Button {...skipProps} sx={styles.btn} variant="outlined" color="primary" size="small">
              {step.locale.skip}
            </Button>
          )}
          {continuous && (
            <Button {...primaryProps} sx={styles.btn} variant="contained" color="primary" size="small">
              {isLastStep ? step.locale.last : step.locale.next}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
