import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { getReducers } from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const getReduxStore = () => {
  const store = configureStore({
    reducer: getReducers(),
    middleware: [sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
};
