import { ReactElement } from 'react';
import { Styles } from 'common/types';
import { Box, Button, Typography } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { ReactComponent as SettingsGraphIcon } from 'common/svg/attr-settings-graph.svg';
import { useWindowSize } from 'common/hooks';
import { useAuth0 } from '@auth0/auth0-react';

interface StyleProps {
  isHideIcon: boolean;
}

const getStyles = ({ isHideIcon }: StyleProps): Styles => ({
  container: { width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '588px',
    height: '316px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 5px 20px rgba(21, 30, 34, 0.03)',
    borderRadius: '20px',
  },
  iconWrapper: {
    padding: 1,
    boxSizing: 'border-box',
    backgroundColor: '#E3EDFD',
    borderRadius: '50%',
    marginBottom: '37px',
  },
  title: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '120%',
    textAlign: 'center',
    color: '#13131B',
  },
  desc: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '180%',
    textAlign: 'center',
    color: '#214254',
    marginBottom: '28px',
  },
  backgroundIcon: {
    display: !isHideIcon ? 'flex' : 'none',
    width: '100px',
    transform: 'translate(750px, -200px)',
  },
});

export function UnknownUserContainer(): ReactElement {
  const { width } = useWindowSize();
  const { logout } = useAuth0();

  const styles = getStyles({ isHideIcon: width < 1120 });

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.backgroundIcon}>
        <SettingsGraphIcon />
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.iconWrapper}>
          <SummarizeIcon color="primary" />
        </Box>
        <Typography sx={styles.title}>This email is not associated with any company</Typography>
        <Typography sx={styles.desc}>Please contact your rep to receive an invite!</Typography>
        <Button variant="contained" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
    </Box>
  );
}
