import { TFunction } from 'i18next';
import { MessageParams } from 'yup/lib/types';

export enum YupValidationTypes {
  required = 0,
  email,
  min,
}

export const yupTranslate =
  (t: TFunction, type: YupValidationTypes, labelKey: string, extra?: number) =>
  ({ path }: MessageParams): string => {
    switch (type) {
      case YupValidationTypes.required:
        return t('yup.validation.required', { label: t(labelKey) || path });
      case YupValidationTypes.email:
        return t('yup.validation.email');
      case YupValidationTypes.min:
        return t('yup.validation.min', { label: t(labelKey) || extra, min: extra });
      default:
        return 'Unknown validation type';
    }
  };
