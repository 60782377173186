import { ApiService } from 'common/services';

class UserSettingsApiService extends ApiService {
  getUserSettingsInfo = (id: number) => {
    return this.get(`/api/settings/users/${id}`);
  };

  patchUserSettingsInfo = (payload: UserSettingsModule.UserSettingsForm) => {
    return this.patchFormData(`/api/settings/users/${payload.id}`, {
      ...(payload.avatar ? { file: payload.avatar } : {}),
      ...(payload.avatar === null ? { avatar: null } : {}),
      ...(payload.fullname ? { name: payload.fullname } : {}),
      ...(payload.newPassword ? { password: payload.newPassword } : {}),
      ...(payload.currentPassword ? { currentPassword: payload.currentPassword } : {}),
    });
  };
}

export const userSettingsApi = new UserSettingsApiService(process.env.REACT_APP_API_URL);
