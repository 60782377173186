const USER_SETTINGS_MODULE = '[user-settings:form]';

const GET_USER_SETTINGS_INFO = `${USER_SETTINGS_MODULE} GET_USER_SETTINGS_INFO`;
const GET_USER_SETTINGS_INFO_SUCCESS = `${USER_SETTINGS_MODULE} GET_USER_SETTINGS_INFO_SUCCESS`;
const GET_USER_SETTINGS_INFO_ERROR = `${USER_SETTINGS_MODULE} GET_USER_SETTINGS_INFO_ERROR`;

const PATCH_USER_SETTINGS_INFO = `${USER_SETTINGS_MODULE} PATCH_USER_SETTINGS_INFO`;
const PATCH_USER_SETTINGS_INFO_SUCCESS = `${USER_SETTINGS_MODULE} PATCH_USER_SETTINGS_INFO_SUCCESS`;
const PATCH_USER_SETTINGS_INFO_ERROR = `${USER_SETTINGS_MODULE} PATCH_USER_SETTINGS_INFO_ERROR`;

export const userSettingsActionTypes = {
  GET_USER_SETTINGS_INFO,
  GET_USER_SETTINGS_INFO_SUCCESS,
  GET_USER_SETTINGS_INFO_ERROR,
  PATCH_USER_SETTINGS_INFO,
  PATCH_USER_SETTINGS_INFO_SUCCESS,
  PATCH_USER_SETTINGS_INFO_ERROR,
};
